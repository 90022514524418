




















































































































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BBadge,
  BSpinner,
  BButton,
  BModal,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import BEditableTable from "bootstrap-vue-editable-table";
import {
  CharteGraphique,
  CouleurCharteGraphique,
} from "@/api/models/options/charteGraphique/charteGraphique";
import { successAlert } from "@/libs/sweetAlerts/alerts";
import { ExtraFilterModel } from "@/api/models/rapportActivites/rapportActivite";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { TypeGraphEnum } from "@/api/models/enums/typeGraph";
import RessifGraphFilters from "@/views/rapport-activites/templates/filters/RessifGraphFilters.vue";

@Component({
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BSpinner,
    BButton,
    BEditableTable,
    BModal,
    BFormSelect,
    BFormSelectOption,
    BFormInput,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
    SearchableVueSelect,
    RessifGraphFilters,
  },
  name: "ressif-apex-bar-chart",
})
export default class RessifApexBarChart extends Vue {
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) description!: string;
  @Prop({ required: true }) graphReference!: string;
  @Prop({ required: true }) charteGraphique!: CharteGraphique;

  @Prop({ required: true }) baseConfig!: any;
  @Prop({ required: true }) series!: any[];
  @Prop({ required: true }) categories!: any[];
  @Prop({ required: true }) hasMultipleSeries!: boolean;

  @Prop({ required: true }) graphDatas!: any;
  @Prop({ required: true }) addCustomLinePayload!: any;
  @Prop({ required: true }) editableTableFields!: any[];

  @Prop({ required: false }) typeGraph!: TypeGraphEnum;
  @Prop({ required: false }) filters!: ExtraFilterModel[];
  @Prop({ required: false, default: true }) updateData!: boolean;

  @Prop({ required: false, default: 15 }) categoriesLineLimit!: number;

  locked = true;
  loading = false;
  chartKey: number = 0;

  disabledButtonTrueData = false;
  disabledButtonRefresh = false;
  displayedTableDataEdition = false;
  triggeredExportList = false;

  copyCharteGraphique: any = {};
  colors: any[] = [];

  dataBeingEdited: any[] = [];
  rowBeingUpdated: any = null;

  usePng = true;

  created() {
    this.copyCharteGraphique = JSON.parse(JSON.stringify(this.charteGraphique));
    this.formatColors();
  }

  isOrdered(array: any) {
    for (let i = 0; i < array.length - 1; i++) {
      if (array[i] > array[i + 1]) {
        return false;
      }
    }
    return true;
  }

  headerClicked(val: any) {
    var isOrdered = this.isOrdered(this.dataBeingEdited.flatMap((x) => x[val]));
    if (isOrdered) {
      this.dataBeingEdited.sort(function (a, b) {
        return b[val] - a[val];
      });
    } else {
      this.dataBeingEdited.sort(function (a, b) {
        return a[val] - b[val];
      });
    }
    this.$emit("data-row-added", {
      values: this.dataBeingEdited,
      graphRef: this.graphReference,
    });
  }

  @Watch("series")
  seriesUpdated(newValue: any[]) {
    this.apexChatData.series = newValue;
    (this.$refs[this.graphReference] as any).updateSeries(newValue);
  }

  @Watch("categories")
  categoriesUpdated(newValue: any[]) {
    const maxLineLength = this.categoriesLineLimit;

    const formattedCategories = newValue.map((category: string) => {
      let currentLine = '';
      let linesArray: string[] = [];

      const words = category.split(' ');

      words.forEach((word) => {
        if ((currentLine + word).length > maxLineLength) {
          linesArray.push(currentLine.trim());
          currentLine = word + ' ';
        } else {
          currentLine += word + ' ';
        }
      });
      
      linesArray.push(currentLine.trim());

      return linesArray;
    });

    this.apexChatData.chartOptions.xaxis.categories = formattedCategories;

    ((this.$refs[this.graphReference] as any) as ApexCharts).updateOptions(
      {
        xaxis: {
          categories: formattedCategories,
        },
      },
      false,
      true,
      true,
    );
  }

  loadTrueData() {
    this.disabledButtonTrueData = true;
    this.loading = true;
    this.displayedTableDataEdition = false;
    this.$emit("load-true-data", this.graphReference);
  }

  getSvg() {
    const paper = (this.$refs[this.graphReference] as any).chart.paper();
    const svg = paper.svg();
    return svg;
  }

  async getPng(c: any) {
    var currentWidth = (this.$refs[this.graphReference] as any).width;
    var currentHeight = (this.$refs[this.graphReference] as any).height;
    let ratio = c.hauteur / c.largeur;

    (this.$refs[this.graphReference] as any).updateOptions({
      chart: {
        width: 1000,
        height: ratio * 1000,
      },
    });

    return new Promise((resolve) => {
      setTimeout(async () => {
        const base64 = await (
          this.$refs[this.graphReference] as any
        ).chart.dataURI();

        (this.$refs[this.graphReference] as any).updateOptions({
          chart: {
            width: currentWidth,
            height: currentHeight,
          },
        });

        resolve(base64.imgURI);
      }, 10200);
    });
  }

  async copyToClipBoard() {
    let keys: string[] = Object.keys(this.graphDatas[0]).slice(1);

    const labels: string[] = keys
      .map((keyToRename: string) => {
        const foundField = this.editableTableFields.find(
          (x: any) => x.key === keyToRename,
        );
        return foundField ? foundField.label : null;
      })
      .filter((label) => label !== null);

    let combinedData: any[][] = [];
    combinedData[0] = labels;

    this.graphDatas.forEach((item: { [key: string]: any }) => {
      const { id, ...newObjet } = item;
      const filteredObj: { [key: string]: any } = {};
      keys.forEach((key, index) => {
        if (newObjet.hasOwnProperty(key)) {
          filteredObj[labels[index]] = newObjet[key];
        }
      });
      combinedData.push(Object.values(filteredObj));
    });

    var array = combinedData;
    var csv = "",
      row,
      cell;

    for (row = 0; row < array.length; row++) {
      for (cell = 0; cell < array[row].length; cell++) {
        csv += (array[row][cell] + "").replace(/[\n\t]+/g, " ");
        if (cell + 1 < array[row].length) csv += "\t";
      }
      if (row + 1 < array.length) csv += "\n";
    }

    await navigator.clipboard.writeText(csv).then(() => {
      successAlert.fire({
        title: "Copie des données",
        text: "Les données ont bien été copiées dans le presse papier",
      });
    });
  }

  downloadSVG() {
    var name = this.title;
    const paper = (this.$refs[this.graphReference] as any).chart.paper();
    const svg = paper.svg();
    var svgBlob = new Blob([svg], { type: "image/svg+xml;charset=utf-8" });
    var svgUrl = URL.createObjectURL(svgBlob);
    var downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    downloadLink.download = name ? name : "graph" + ".svg";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  toggleModalColorEdit() {
    this.$bvModal.show("modalColorEdit" + this.graphReference);
  }
  getRandomColor(): string {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  }
  addColor() {
    let rdm = this.getRandomColor();
    if (this.copyCharteGraphique?.couleurCharteGraphiques) {
      this.copyCharteGraphique.couleurCharteGraphiques.push({
        ordre:
          this.copyCharteGraphique.couleurCharteGraphiques?.length.toString(),
        code: rdm,
      });

      this.formatColors();
    }
  }
  removeColor() {
    if (this.copyCharteGraphique?.couleurCharteGraphiques) {
      this.copyCharteGraphique.couleurCharteGraphiques.pop();
      this.formatColors();
    }
  }
  formatColors() {
    this.copyCharteGraphique?.couleurCharteGraphiques?.forEach(
      (couleur: CouleurCharteGraphique) => {
        this.colors?.push(couleur.code);
      },
    );
    this.apexChatData.chartOptions.colors = this.colors;
    this.chartKey++;
  }
  updateColors(operation: any, index: any) {
    if (
      this.copyCharteGraphique &&
      this.copyCharteGraphique.couleurCharteGraphiques
    )
      this.copyCharteGraphique.couleurCharteGraphiques.splice(index, 1, {
        code: operation,
        ordre: index,
      });
  }
  saveNewCharte() {
    if (this.colors) {
      this.colors = [];
    }
    this.formatColors();
  }

  toggleFilterEdit() {
    (this.$refs["graphFilters" + this.graphReference] as any).openModal();
  }

  displayDataEdition() {
    if (this.displayedTableDataEdition) {
      this.displayedTableDataEdition = false;
      this.dataBeingEdited = [];
    } else {
      this.dataBeingEdited = JSON.parse(JSON.stringify(this.graphDatas));
      this.displayedTableDataEdition = true;
    }
  }
  handleEdit(data: any) {
    this.rowBeingUpdated = { edit: true, id: data.id };
  }
  handleAdd() {
    let idToUse = this.dataBeingEdited.length + Math.random() * 1;
    let objToAdd = {
      ...this.addCustomLinePayload,
      id: idToUse,
    };
    this.dataBeingEdited.push(objToAdd);
    this.rowBeingUpdated = { edit: true, id: idToUse };
  }
  handleSubmit(data: any, update: boolean) {
    this.rowBeingUpdated = {
      edit: false,
      id: data.id,
      action: update ? "update" : "cancel",
    };

    if (update) {
      this.dataBeingEdited[data.index] = data.item;
      this.$emit("data-row-update", {
        values: this.dataBeingEdited,
        graphRef: this.graphReference,
      });
      this.$emit("data-row-added", {
        values: this.dataBeingEdited,
        graphRef: this.graphReference,
      });
    } else {
      this.$emit("data-row-added", {
        values: this.dataBeingEdited,
        graphRef: this.graphReference,
      });
    }
  }
  handleDelete(data: any) {
    this.$emit("data-row-deleted", {
      id: data.id,
      graphRef: this.graphReference,
    });
    this.rowBeingUpdated = { id: data.id, action: "delete" };
  }

  apexChatData = this.baseConfig;
}
