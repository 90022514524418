
















import { Component, Prop, Vue } from "vue-property-decorator";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { BButton } from "bootstrap-vue";
import RessifApexBarChart from "../templates/bar-charts/RessifApexBarChart.vue";
import { CharteGraphique } from "@/api/models/options/charteGraphique/charteGraphique";
import { TypeGraphEnum } from "@/api/models/enums/typeGraph";

@Component({
  components: {
    SearchableVueSelect,
    BButton,
    RessifApexBarChart,
  },
  name: "bar-consultations-ist-by-localisations-douleurs-only-at-chart",
})
export default class BarConsultationsIstByLocalisationsDouleursOnlyAtChart extends Vue {
  @Prop({ required: true }) charteGraphique!: CharteGraphique;
  @Prop({ required: true }) model!: any;
  @Prop({ required: true }) reference!: any;

  title = "Accompagnement individuel";
  description =
    "(025) Répartition du nombre de consultations IST en Pathologie et soin liés au travail par localisations des douleurs (uniquement sous catégories AT)";

  tableFields = [
    { key: "douleur", label: "Douleur", type: "text", editable: true },
    { key: "nombreAT", label: "Nombre AT", type: "number", editable: true },
    { key: "nombreATBenins", label: "Nombre AT Bénins", type: "number", editable: true },
    { key: "nombreATMission", label: "Nombre AT Mission", type: "number", editable: true },
    { key: "nombreAccidentTrajet", label: "Nombre Accident Trajet", type: "number", editable: true },
    { key: "actions", label: "Actions", editable: false },
  ];

  sampleDatas = [
    {
      id: 1,
      douleur: "Main",
      nombreAT: 4,
      nombreATBenins: 6,
      nombreATMission: 8,
      nombreAccidentTrajet: 10,
    },
    {
      id: 2,
      douleur: "Tête",
      nombreAT: 12,
      nombreATBenins: 14,
      nombreATMission: 16,
      nombreAccidentTrajet: 18,
    },
    {
      id: 3,
      douleur: "Psychique",
      nombreAT: 18,
      nombreATBenins: 20,
      nombreATMission: 22,
      nombreAccidentTrajet: 24,
    },
    {
      id: 4,
      douleur: "Cou",
      nombreAT: 18,
      nombreATBenins: 20,
      nombreATMission: 22,
      nombreAccidentTrajet: 24,
    },
  ]

  addLinePayload = {
    douleur: "Autres",
    nombreAT: 0,
    nombreATBenins: 0,
    nombreATMission: 0,
    nombreAccidentTrajet: 0,
  };

  seriesArr: any[] | null = null;
  categoriesArr: any[] | null = null;

  typeGraph = TypeGraphEnum.Individuel;
  modelCopy: any | null = null;

  created() {
    this.modelCopy = JSON.parse(JSON.stringify(this.model));
  }

  onRowDeleted(payload: any) {
    this.sampleDatas = this.sampleDatas.filter(x => x.id !== payload.id)
    // Nécessaire car editable-table demande un délai pour l'update
    setTimeout(async () => {
      let dataAT = this.sampleDatas.map((x: any) => parseInt(x.nombreAT))
      let dataATBenins = this.sampleDatas.map((x: any) => parseInt(x.nombreATBenins))
      let dataATMission = this.sampleDatas.map((x: any) => parseInt(x.nombreATMission))
      let dataAccidentTrajet = this.sampleDatas.map((x: any) => parseInt(x.nombreAccidentTrajet))

      let douleurs = this.sampleDatas.map((x: any) => x.douleur);

      this.seriesArr = [
        {
          key: "AT",
          name: "AT",
          data: dataAT,
        },
        {
          key: "AT Bénins",
          name: "AT Bénins",
          data: dataATBenins,
        },
        {
          key: "AT Mission",
          name: "AT Mission",
          data: dataATMission,
        },
        {
          key: "Accident Trajet",
          name: "Accident Trajet",
          data: dataAccidentTrajet,
        },
      ];

      this.categoriesArr = douleurs as any[];

      (this.$refs[payload.graphRef] as any).$refs[payload.graphRef].updateOptions(
        {
          xaxis: {
            categories: this.categoriesArr
          }
        }, false, true, true
      )
    }, 200);
  }

  onRowAdded(payload: any) {

    this.sampleDatas = payload.values

    // Nécessaire car editable-table demande un délai pour l'update
    setTimeout(async () => {
      let dataAT = this.sampleDatas.map((x: any) => parseInt(x.nombreAT))
      let dataATBenins = this.sampleDatas.map((x: any) => parseInt(x.nombreATBenins))
      let dataATMission = this.sampleDatas.map((x: any) => parseInt(x.nombreATMission))
      let dataAccidentTrajet = this.sampleDatas.map((x: any) => parseInt(x.nombreAccidentTrajet))

      let douleurs = this.sampleDatas.map((x: any) => x.douleur);

      this.seriesArr = [
        {
          key: "AT",
          name: "AT",
          data: dataAT,
        },
        {
          key: "AT Bénins",
          name: "AT Bénins",
          data: dataATBenins,
        },
        {
          key: "AT Mission",
          name: "AT Mission",
          data: dataATMission,
        },
        {
          key: "Accident Trajet",
          name: "Accident Trajet",
          data: dataAccidentTrajet,
        },
      ];

      this.categoriesArr = douleurs as any[];

      (this.$refs[payload.graphRef] as any).$refs[payload.graphRef]?.updateOptions(
        {
          xaxis: {
            categories: this.categoriesArr
          }
        }, false, true, true
      )
    }, 200);
  }

  async loadConsultationsByExamensComplementaire(graphRef: any, config: any) {
    this.model.maxCategoryNumber = config?.maxCategoryNumber;
    this.model.otherLibelle = config?.otherLibelle;

    await this.$http.ressifnet.rapportActivites
      .repartitionConsultationsISTByLocalisationsDouleursOnlyAT(this.model)
      .then(async (response: any) => {
        let dataAT = response.map((x: any) => parseInt(x.nombreAT))
        let dataATBenins = response.map((x: any) => parseInt(x.nombreATBenins))
        let dataATMission = response.map((x: any) => parseInt(x.nombreATMission))
        let dataAccidentTrajet = response.map((x: any) => parseInt(x.nombreAccidentTrajet))

        let douleurs = response.map((x: any) => x.douleur);
        this.seriesArr = [
          {
            key: "AT",
            name: "AT",
            data: dataAT,
          },
          {
            key: "AT Bénins",
            name: "AT Bénins",
            data: dataATBenins,
          },
          {
            key: "AT Mission",
            name: "AT Mission",
            data: dataATMission,
          },
          {
            key: "Accident Trajet",
            name: "Accident Trajet",
            data: dataAccidentTrajet,
          },
        ];
        this.categoriesArr = douleurs as any[];

        this.sampleDatas = response.map((x: any) => ({
          douleur: x.douleur,
          nombreAT: x.nombreAT,
          nombreATBenins: x.nombreATBenins,
          nombreATMission: x.nombreATMission,
          nombreAccidentTrajet: x.nombreAccidentTrajet,
        }));

        (this.$refs[graphRef] as any).$refs[graphRef]?.updateSeries(this.seriesArr, false, true, true);
        (this.$refs[graphRef] as any).$refs[graphRef]?.updateOptions(
          {
            xaxis: {
              categories: this.categoriesArr
            }
          }, false, true, true
        );
        (this.$refs[graphRef] as any).loading = false;
        (this.$refs[graphRef] as any).locked = false;
      }).catch((e: any) => {
        (this.$refs[graphRef] as any).loading = false;
        (this.$refs[graphRef] as any).locked = true;
        (this.$refs[graphRef] as any).disabledButtonTrueData = false;

        let message = e.response?.data?.errors ?
          `Un problème est survenu lors du chargement du graphique: ${Object.values(e.response.data.errors)[0]}` :
          "Un problème est survenu lors du chargement du graphique";

        this.$swal({
          title: 'Erreur',
          text: message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          buttonsStyling: false,
        })
      })
  }

  async loadTrueData(graphRef: any, config: any) {
    await this.loadConsultationsByExamensComplementaire(graphRef, config);
  }

  toggleExportToList(graphRef: any) {
    this.$emit("toggle-export-to-list", graphRef);
  }

  baseConfig = {
    series: [
      {
        key: "AT",
        name: "AT",
        data: [8, 5, 1, 6],
      },
      {
        key: "AT Bénins",
        name: "AT Bénins",
        data: [2, 4, 1, 8],
      },
      {
        key: "AT Mission",
        name: "AT Mission",
        data: [8, 2, 6, 0],
      },
      {
        key: "Accident Trajet",
        name: "Accident Trajet",
        data: [8, 2, 6, 2],
      },
    ],
    chartOptions: {
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val
        },
        style: {
          fontSize: '16px'
        }
      },
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '18px',
        width: 550,
        offsetX: 50,
      },
      stroke: {
        show: true,
        colors: ['transparent'],
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories: ['Tête', 'Mains', 'Psychique', 'Cou'],
        labels: {
          style: {
            fontSize: '18px'
          }
        }
      },
      yaxis: {
        labels: {
          show: true,
          trim: false,
          offsetY: 15,
          style: {
            fontSize: '18px'
          }
        }
        // opposite: isRtl,
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return val
          }
        }
      },
    }
  }
}
