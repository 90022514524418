






























import { Component, Prop, Vue } from "vue-property-decorator";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { BButton } from "bootstrap-vue";
import RessifApexBarChart from "../templates/bar-charts/RessifApexBarChart.vue";
import { CharteGraphique } from "@/api/models/options/charteGraphique/charteGraphique";
import { TypeGraphEnum } from "@/api/models/enums/typeGraph";

@Component({
  components: {
    SearchableVueSelect,
    BButton,
    RessifApexBarChart,
  },
  name: "bar-consultations-ist-by-type-visite-chart",
})
export default class BarConsultationsIstByTypeVisiteChart extends Vue {
  @Prop({ required: true }) charteGraphique!: CharteGraphique;
  @Prop({ required: true }) model!: any;
  @Prop({ required: true }) reference!: any;

  title = "Accompagnement Individuel";
  description =
    "(016) Répartition du nombre de consultations IST par type de visite";

  tableFields = [
    { key: "typeVisite", label: "Type de visite", type: "text", editable: true },
    { key: "nombrePI", label: "Nombre de consultations", type: "number", editable: true },
    { key: "actions", label: "Actions", editable: false },
  ];

  sampleDatas = [
  {
    id: 1,
    typeVisite: "Suivi IST",
    nombrePI: 12
  },
  {
    id: 2,
    typeVisite: "VIP initiale",
    nombrePI: 24
  },
  {
    id: 3,
    typeVisite: "VIP Périodiques",
    nombrePI: 37
  }
]

  addLinePayload = {
    typeVisite: "Autres",
    nombrePI: 0
  };

  seriesArr: any[] | null = null;
  categoriesArr: any[] | null = null;

  typeGraph = TypeGraphEnum.Individuel;
  modelCopy: any | null = null;

  created() {
    this.modelCopy = JSON.parse(JSON.stringify(this.model));
  }

  onRowDeleted(payload: any) {
    this.sampleDatas = this.sampleDatas.filter(x => x.id !== payload.id)
    // Nécessaire car editable-table demande un délai pour l'update
    setTimeout(() => {
      var payloadMapped = this.sampleDatas.map((x: any) => x.nombrePI)
      let dataArray = payloadMapped
      let seriesArray = this.sampleDatas.map((x: any) => x.typeVisite)

      this.seriesArr = [
        {
          key: "Nombre",
          name: "Nombre",
          data: dataArray
        },
      ];
      this.categoriesArr = seriesArray as any[]
      (this.$refs[payload.graphRef] as any).$refs[payload.graphRef].updateOptions(
        {
          xaxis: {
            categories: this.categoriesArr
          }
        }, false, true, true
      )
    }, 200);
  }

  onRowAdded(payload: any) {

    this.sampleDatas = payload.values

    // Nécessaire car editable-table demande un délai pour l'update
    setTimeout(() => {
      var payloadMapped = this.sampleDatas.map((x: any) => x.nombrePI)
      let dataArray = payloadMapped
      let seriesArray = this.sampleDatas.map((x: any) => x.typeVisite)

      this.seriesArr = [
        {
          key: "Nombre",
          name: "Nombre",
          data: dataArray
        },
      ];
      this.categoriesArr = seriesArray as any[];

      (this.$refs[payload.graphRef] as any).$refs[payload.graphRef]?.updateOptions(
        {
          xaxis: {
            categories: this.categoriesArr
          }
        }, false, true, true
      )
    }, 200);
  }

  async loadConsultationsByTypeVisite(graphRef: any, config: any) {
    this.model.maxCategoryNumber = config?.maxCategoryNumber;
    this.model.otherLibelle = config?.otherLibelle;

    await this.$http.ressifnet.rapportActivites
      .repartitionConsultationsISTByTypeVisite(this.model)
      .then((response: any) => {
        var responseMapped = response.map((x: any) => x.nombrePI)
        let dataArray = responseMapped
        let seriesArray = response.map((x: any) => x.typeVisite)

        this.seriesArr = [
          {
            key: "Nombre",
            name: "Nombre",
            data: dataArray
          },
        ];

        this.categoriesArr = seriesArray as any[]
        this.sampleDatas = response.map((x: any, index: number) => {
          return {
            id: x.id,
            typeVisite: x.typeVisite,
            nombrePI: x.nombrePI
          }
        });

        (this.$refs[graphRef] as any).$refs[graphRef]?.updateOptions(
          {
            xaxis: {
              categories: this.categoriesArr
            }
          }, false, true, true
        );
        (this.$refs[graphRef] as any).loading = false;
        (this.$refs[graphRef] as any).locked = false;
      }).catch((e: any) => {
        (this.$refs[graphRef] as any).loading = false;
        (this.$refs[graphRef] as any).locked = true;
        (this.$refs[graphRef] as any).disabledButtonTrueData= false;

        let message = e.response?.data?.errors ? 
          `Un problème est survenu lors du chargement du graphique: ${Object.values(e.response.data.errors)[0]}` :
          "Un problème est survenu lors du chargement du graphique";

        this.$swal({
          title: 'Erreur',
          text: message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          buttonsStyling: false,
        })
      })
  }

  async loadTrueData(graphRef: any, config: any) {
    await this.loadConsultationsByTypeVisite(graphRef, config);
  }

  toggleExportToList(graphRef: any) {
    this.$emit("toggle-export-to-list", graphRef);
  }
  
  baseConfig = {
    series: [
      {
        key: "NombrePI",
        name: "NombrePI",
        data: [10, 20, 30],
      }
    ],
    chartOptions: {
      chart: {
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: false,
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any, opts: any) {
          // Calculer le pourcentage
          let series = opts.w.globals.series[opts.seriesIndex];
          let total = series.reduce((a: any, b: any) => a + b, 0);
          let percentage = ((val / total) * 100).toFixed(2); // Formater à deux décimales

          let percentString = percentage+"%";
          let valueString = "("+val+")";
          
          return [percentString, valueString];
        },
          style: {
            fontSize: '15px'
          }
      },
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '18px',
        width: 550,
        offsetX: 50,
      },
      stroke: {
        show: true,
        colors: ['transparent'],
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories: this.sampleDatas.map((x: any) => x.typeVisite),
        labels: {
          style: {
            fontSize: '18px'
          }
        }
      },
      yaxis: {
        labels: {
          show: true,
          trim: false,
          style : {
            fontSize: '18px'
          }
        }
        // opposite: isRtl,
      },
      fill: {
        opacity: 1,
      },
    }
  }
}
