















import { Component, Prop, Vue } from "vue-property-decorator";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { BButton } from "bootstrap-vue";
import RessifApexBarChart from "../templates/bar-charts/RessifApexBarChart.vue";
import { CharteGraphique } from "@/api/models/options/charteGraphique/charteGraphique";
import { TypeGraphEnum } from "@/api/models/enums/typeGraph";

@Component({
  components: {
    SearchableVueSelect,
    BButton,
    RessifApexBarChart,
  },
  name: "bar-consultations-ist-by-localisations-douleurs-only-mp-and-tms-chart",
})
export default class BarConsultationsIstByLocalisationsDouleursOnlyMpAndTmsChart extends Vue {
  @Prop({ required: true }) charteGraphique!: CharteGraphique;
  @Prop({ required: true }) model!: any;
  @Prop({ required: true }) reference!: any;

  title = "Accompagnement individuel";
  description =
    "(026) Répartition du nombre de consultations IST en Pathologie et soin liés au travail par localisations des douleurs (uniquement sous catégories MP et TMS)";

  tableFields = [
    { key: "douleur", label: "Douleur", type: "text", editable: true },
    { key: "nombreMP", label: "Nombre MP", type: "number", editable: true },
    { key: "nombreTMS", label: "Nombre TMS", type: "number", editable: true },
    { key: "actions", label: "Actions", editable: false },
  ];

  sampleDatas = [
    {
      id: 1,
      douleur: "Main",
      nombreMP: 4,
      nombreTMS: 6,
    },
    {
      id: 2,
      douleur: "Tête",
      nombreMP: 12,
      nombreTMS: 6,
    },
    {
      id: 3,
      douleur: "Psychique",
      nombreMP: 18,
      nombreTMS: 6,
    },
    {
      id: 4,
      douleur: "Cou",
      nombreMP: 18,
      nombreTMS: 6,
    },
  ]

  addLinePayload = {
    douleur: "Autres",
    nombreMP: 0,
    nombreTMS: 0,
  };

  seriesArr: any[] | null = null;
  categoriesArr: any[] | null = null;

  typeGraph = TypeGraphEnum.Individuel;
  modelCopy: any | null = null;

  created() {
    this.modelCopy = JSON.parse(JSON.stringify(this.model));
  }

  onRowDeleted(payload: any) {
    this.sampleDatas = this.sampleDatas.filter(x => x.id !== payload.id)
    // Nécessaire car editable-table demande un délai pour l'update
    setTimeout(async () => {
      let dataMP = this.sampleDatas.map((x: any) => parseInt(x.nombreMP))
      let dataTMS = this.sampleDatas.map((x: any) => parseInt(x.nombreTMS))

      let douleurs = this.sampleDatas.map((x: any) => x.douleur);

      this.seriesArr = [
        {
          key: "MP",
          name: "MP",
          data: dataMP,
        },
        {
          key: "TMS",
          name: "TMS",
          data: dataTMS,
        }
      ];

      this.categoriesArr = douleurs as any[];

      (this.$refs[payload.graphRef] as any).$refs[payload.graphRef].updateOptions(
        {
          xaxis: {
            categories: this.categoriesArr
          }
        }, false, true, true
      )
    }, 200);
  }

  onRowAdded(payload: any) {

    this.sampleDatas = payload.values

    // Nécessaire car editable-table demande un délai pour l'update
    setTimeout(async () => {
      let dataMP = this.sampleDatas.map((x: any) => parseInt(x.nombreMP))
      let dataTMS = this.sampleDatas.map((x: any) => parseInt(x.nombreTMS))

      let douleurs = this.sampleDatas.map((x: any) => x.douleur);

      this.seriesArr = [
        {
          key: "MP",
          name: "MP",
          data: dataMP,
        },
        {
          key: "TMS",
          name: "TMS",
          data: dataTMS,
        }
      ];

      this.categoriesArr = douleurs as any[];

      (this.$refs[payload.graphRef] as any).$refs[payload.graphRef]?.updateOptions(
        {
          xaxis: {
            categories: this.categoriesArr
          }
        }, false, true, true
      )
    }, 200);
  }

  async loadConsultationsByExamensComplementaire(graphRef: any, config: any) {
    this.model.maxCategoryNumber = config?.maxCategoryNumber;
    this.model.otherLibelle = config?.otherLibelle;
    await this.$http.ressifnet.rapportActivites
      .repartitionConsultationsISTByLocalisationsDouleursOnlyMPAndTMS(this.model)
      .then(async (response: any) => {
        let dataMP = response.map((x: any) => parseInt(x.nombreMP))
        let dataTMS = response.map((x: any) => parseInt(x.nombreTMS))

        let douleurs = response.map((x: any) => x.douleur);
        this.seriesArr = [
          {
            key: "MP",
            name: "MP",
            data: dataMP,
          },
          {
            key: "TMS",
            name: "TMS",
            data: dataTMS,
          },
        ];
        this.categoriesArr = douleurs as any[];

        this.sampleDatas = response.map((x: any) => ({
          douleur: x.douleur,
          nombreMP: x.nombreMP,
          nombreTMS: x.nombreTMS,
        }));

        (this.$refs[graphRef] as any).$refs[graphRef]?.updateSeries(this.seriesArr, false, true, true);
        (this.$refs[graphRef] as any).$refs[graphRef]?.updateOptions(
          {
            xaxis: {
              categories: this.categoriesArr
            }
          }, false, true, true
        );
        (this.$refs[graphRef] as any).loading = false;
        (this.$refs[graphRef] as any).locked = false;
      }).catch((e: any) => {
        (this.$refs[graphRef] as any).loading = false;
        (this.$refs[graphRef] as any).locked = true;
        (this.$refs[graphRef] as any).disabledButtonTrueData = false;

        let message = e.response?.data?.errors ?
          `Un problème est survenu lors du chargement du graphique: ${Object.values(e.response.data.errors)[0]}` :
          "Un problème est survenu lors du chargement du graphique";

        this.$swal({
          title: 'Erreur',
          text: message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          buttonsStyling: false,
        })
      })
  }

  async loadTrueData(graphRef: any, config: any) {
    await this.loadConsultationsByExamensComplementaire(graphRef, config);
  }

  toggleExportToList(graphRef: any) {
    this.$emit("toggle-export-to-list", graphRef);
  }

  baseConfig = {
    series: [
      {
        key: "MP",
        name: "MP",
        data: [8, 5, 1, 6],
      },
      {
        key: "TMS",
        name: "TMS",
        data: [2, 4, 1, 8],
      },
    ],
    chartOptions: {
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val
        },
        style: {
          fontSize: '16px'
        }
      },
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '18px',
        width: 550,
        offsetX: 50,
      },
      stroke: {
        show: true,
        colors: ['transparent'],
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories: ['Tête', 'Mains', 'Psychique', 'Cou'],
        labels: {
          style: {
            fontSize: '16px'
          }
        }
      },
      yaxis: {
        labels: {
          show: true,
          trim: false,
          style: {
            fontSize: '16px'
          },
          offsetY: 10
        }
        // opposite: isRtl,
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return val
          }
        }
      },
    }
  }
}
