






























import VueApexCharts from "vue-apexcharts";
import { Component, Prop, Vue } from "vue-property-decorator";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { BButton } from "bootstrap-vue";
import RessifApexBarChart from "../templates/bar-charts/RessifApexBarChart.vue";
import { CharteGraphique } from "@/api/models/options/charteGraphique/charteGraphique";
import { TypeGraphEnum } from "@/api/models/enums/typeGraph";

@Component({
  components: {
    SearchableVueSelect,
    BButton,
    RessifApexBarChart,
    VueApexCharts,
  },
  name: "bar-temps-by-theme-projet-psp-chart",
})
export default class BarTempsByThemeProjetPspChart extends Vue {
  @Prop({ required: true }) charteGraphique!: CharteGraphique;
  @Prop({ required: true }) model!: any;
  @Prop({ required: true }) reference!: any;

  title = "Entreprise - Prévention santé Publique";
  description =
    "(605) Répartition des temps passés par thème de projet (Prévention Santé Publique)";

  tableFields = [
    {
      key: "themeProjet",
      label: "Théme du projet",
      type: "text",
      editable: true,
    },
    { key: "temps", label: "Temps", type: "number", editable: true },
    { key: "actions", label: "Actions", editable: false },
  ];

  sampleDatas = [
    {
      id: 1,
      themeProjet: "1",
      temps: 10,
    },
    {
      id: 2,
      themeProjet: "2",
      temps: 20,
    },
    {
      id: 3,
      themeProjet: "3",
      temps: 34,
    },
  ];

  addLinePayload = {
    themeProjet: "Autres",
    temps: 0,
  };

  seriesArr: any[] | null = null;
  categoriesArr: any[] | null = null;

  typeGraph = TypeGraphEnum.Individuel;
  modelCopy: any | null = null;

  created() {
    this.modelCopy = JSON.parse(JSON.stringify(this.model));
  }

  onRowDeleted(payload: any) {
    this.sampleDatas = this.sampleDatas.filter((x) => x.id !== payload.id);

    setTimeout(() => {
      this.sampleDatas = this.sampleDatas.sort((a, b) => a.temps - b.temps);

      let dataArray = this.sampleDatas.map((x: any) => parseFloat(x.temps.toFixed(2)))
      let seriesArray = this.sampleDatas.map((x: any) => x.themeProjet);

      this.seriesArr = [
        {
          key: "Temps",
          name: "Temps",
          data: dataArray,
        },
      ];
      this.categoriesArr = seriesArray as any[];
      (this.$refs[payload.graphRef] as any).$refs[
        payload.graphRef
      ].updateOptions(
        {
          xaxis: {
            categories: this.categoriesArr,
          },
        },
        false,
        true,
        true
      );
    }, 200);
  }

  onRowAdded(payload: any) {
    this.sampleDatas = payload.values;

    // Nécessaire car editable-table demande un délai pour l'update
    setTimeout(() => {
      this.sampleDatas = this.sampleDatas.sort((a, b) => a.temps - b.temps);

      let dataArray = this.sampleDatas.map((x: any) => parseFloat(x.temps.toFixed(2)))
      let seriesArray = this.sampleDatas.map((x: any) => x.themeProjet);

      this.seriesArr = [
        {
          key: "Temps",
          name: "Temps",
          data: dataArray,
        },
      ];
      this.categoriesArr = seriesArray as any[];

      (this.$refs[payload.graphRef] as any).$refs[
        payload.graphRef
      ]?.updateOptions(
        {
          xaxis: {
            categories: this.categoriesArr,
          },
        },
        false,
        true,
        true
      );
    }, 200);
  }

  async loadData(graphRef: any, config: any) {
    this.model.maxCategoryNumber = config?.maxCategoryNumber;
    this.model.otherLibelle = config?.otherLibelle;

    await this.$http.ressifnet.rapportActivites
      .repartitionTempsByThemeProjetPsp(this.model)
      .then((response: any) => {
        response = response.sort((a: any, b: any) => a.temps - b.temps);

        let dataArray = response.map((x: any) => parseFloat(x.temps.toFixed(2)))
        let seriesArray = response.map((x: any) => x.themeProjet);

        this.seriesArr = [
          {
            key: "Temps",
            name: "Temps",
            data: dataArray,
          },
        ];

        this.categoriesArr = seriesArray as any[];
        this.sampleDatas = response as any[];

        (this.$refs[graphRef] as any).$refs[graphRef]?.updateOptions(
          {
            xaxis: {
              categories: this.categoriesArr,
            },
          },
          true,
          true,
          true
        );
        (this.$refs[graphRef] as any).loading = false;
        (this.$refs[graphRef] as any).locked = false;
      })
      .catch((e: any) => {
        (this.$refs[graphRef] as any).loading = false;
        (this.$refs[graphRef] as any).locked = true;
        (this.$refs[graphRef] as any).disabledButtonTrueData = false;

        let message = e.response?.data?.errors
          ? `Un problème est survenu lors du chargement du graphique: ${
              Object.values(e.response.data.errors)[0]
            }`
          : "Un problème est survenu lors du chargement du graphique";

        this.$swal({
          title: "Erreur",
          text: message,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
      });
  }
  async loadTrueData(graphRef: any, config: any) {
    await this.loadData(graphRef, config);
  }

  toggleExportToList(graphRef: any) {
    this.$emit("toggle-export-to-list", graphRef);
  }

  baseConfig = {
    series: [
      {
        key: "Pourcentage",
        name: "Pourcentage",
        data: [10, 20, 30],
      },
    ],
    chartOptions: {
      chart: {
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: true,
          distributed: true,
          isFunnel: false,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any, opts: any) {
          const index = opts.dataPointIndex;

          let themeProjet = opts.w.config.xaxis.categories[index];
          opts.seriesIndex++;

          if (Array.isArray(themeProjet)) {
            themeProjet = themeProjet.join(" ");
          }

          const nombre = val;
          const total = opts.w.config.series[0].data.reduce(
            (acc: number, curr: number) => acc + curr,
            0
          );
          const percentage = ((val / total) * 100).toFixed(0);

          return [`${nombre} h`];
        },
        style: {
          fontSize: '15px'
        }
      },
      legend: {
        show: true,
        position: "bottom",
        fontSize: "18px",
        width: 550,
        offsetX: 50,
        formatter: function (val: any, opts: any) {
          const index = opts.seriesIndex;

          const nombre = opts.w.config.series[0].data[index];
          const total = opts.w.config.series[0].data.reduce(
            (acc: number, curr: number) => acc + curr,
            0
          );

          const percentage = ((nombre / total) * 100).toFixed(0);


          return [[`${val} (${nombre} heures) : ${percentage}%`]];
        },
      },
      stroke: {
        show: true,
        colors: ["transparent"],
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories: this.sampleDatas.map((x: any) => x.themeProjet),
        labels: {
          style: {
            fontSize: "16px",
          },
        },
      },
      yaxis: {
        labels: {
          show: true,
          trim: false,
          offsetY: 15,
          style: {
            fontSize: "16px",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: (val: any) => {
            return val + " (heures)";
          },
        },
      },
    },
  };
}
